<template>
  <div class="managers-order-history">

    <n-card :loading="$var('load')">
      <h4>
        <slot name="header">
        </slot>
      </h4>
      <table v-if="data.length !== 0 || this.$var('load')">
        <tr class="table-headers">
          <td>Номер заявки</td>
          <td>Статус заявки</td>
          <td>Дата заявки</td>
          <td>Дата отправленного КП</td>
          <td>Кол-во товаров</td>
        </tr>
        <tr v-for="item in data" :key="item.id">
          <td v-if="item.number">
            <n-link :to="{name: 'orders.index', params:{ id: item.id}}">
              {{ item.number }}
            </n-link>
          </td>
          <td v-else>
            <n-link :to="{name: 'orders.index', params:{ id: item.id}}">
              {{ item.id }}
            </n-link>
          </td>
          <td>{{ item.status }}</td>
          <td>{{ $app.date.format(item.createdAt) }}</td>
          <td>{{ $app.date.format(item.endedAt) }}</td>
          <td>{{ item.products.length }}</td>
        </tr>
      </table>
      <div v-else>
        <h3 class="empty-message">
          <slot name="empty-message">
          </slot>
        </h3>
      </div>
    </n-card>
  </div>
</template>

<script>
export default {
  name: "ManagersOrderHistory",
  props: {
    managerId: {type: Number, default: 0}
  },
  data: () => {
    return {
      data: [],
    }
  },
  mounted() {
    this.loadStats()
  },
  methods: {
    loadStats() {
      this.$var('load', true)
      $api.app.orders.get().filter({managerId: this.managerId}).with('products').all().then((res) => {
        this.data = res.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.managers-order-history {
  table {
    width: 100%;
    .table-headers {
      width: 20%;
      background: #ECEFF1;
      color: #999;
      font-size: .9em;
      font-weight: 300;
      white-space: nowrap;
    }
  }
  td {
    padding: 10px 15px;
    border-bottom: 2px solid #f6f6f6;
  }
}
</style>
