<template>
  <div class="manager-card">
    <n-card :loading="$var('load')">
      <div class="card-info">
        <div v-for="item in [managerData]" :key="item.id" class="info-block">
          <template>
            <div class="edit-container">
              <div class="profile-avatar" v-if="managerData.avatar || $var('load')">
                <n-image round centered :src="managerData.avatar" />
              </div>
              <div class="profile-avatar" v-else>
                <n-image round centered :src="avatar" />
              </div>
            </div>
            <div class="table-container">
              <table class="manager-table">
                <tr class="table-headers">
                  <td>ФИО</td>
                  <td>Номер телефона</td>
                  <td>Почта</td>
                </tr>
                <tr v-for="item in [managerData]" :key="item.id">
                  <td>{{ item.fullName }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.email }}</td>
                </tr>
              </table>
              <table class="stats-table">
                <tr class="table-headers">
                  <td>Кол-во текущих заявок</td>
                  <td>Всего обработано заявок</td>
                  <td>Успешно завершенные</td>
                </tr>
                <tr v-for="item in [managerData]" :key="item.id">
                  <td>{{ item.stats.activeCount }}</td>
                  <td>{{ item.stats.applicationsCount }}</td>
                  <td>{{ item.stats.finishedCount }}</td>
                </tr>
              </table>
            </div>
          </template>
        </div>
      </div>
    </n-card>
    <managers-order-history :managerId="Number(id)">
      <template v-slot:header>
        История заявок
      </template>
      <template v-slot:empty-message>
        У {{ managerData.fullName }} еще нет заявок...
      </template>
    </managers-order-history>
  </div>
</template>

<script>
import avatar from '../../assets/images/avatar.png'
import ManagersOrderHistory from "./ManagersOrderHistory";
export default {
  name: 'Card',
  components: {
    ManagersOrderHistory,
  },
  data: () => ({
    data: [],
    avatar,
    columns: [
      { name: 'order', title: 'Номер заявки', },
      { name: 'status', title: 'Статус', },
      { name: 'date', title: 'Дата поступления', },
      { name: 'number', title: 'Кол-во позиций', },
      { name: 'finished', title: 'Сформированное КП', },
    ],
    managerData: [],
    managerColumns: [
      { name: 'fullName', title: 'ФИО', },
      { name: 'phone', title: 'Телефон', },
      { name: 'email', title: 'Почта', },
    ],
  }),
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  mounted() {
    //this.loadStats()
    this.loadManager()
  },
  methods: {
    loadManager() {
      this.$var('load', true)
      $api.auth.users.get(this.id).with('stats').then((response) => {
        this.managerData = response.data.content
        this.$form.init(this.managerData)
      }).finally(() => {
        this.$var('load', false)
      })
    },
    loadStats() {

      $api.app.managers.getStats(this.id).then((response) => {
        this.data = response.data.content
      }).finally(() => {

      })
    },
  },
}
</script>

<style lang="scss" scoped>
.manager-card {
  table {
    width: 800px;
    margin-left: 20px;
    height: 100px;
    .table-headers {
      width: 20%;
      background: #ECEFF1;
      color: #999;
      font-size: .9em;
      font-weight: 300;
      white-space: nowrap;
    }
  }
  td {
    padding: 10px 15px;
    border-bottom: 2px solid #f6f6f6;

  }
  .manager-table {
    margin-bottom: 40px;
  }
  .info-block {
    display: flex;
    width: 100%;
    --n-image-width: 180px;
    --n-image-height: 180px;
  }
  .card-info {
    display: flex;
    margin-bottom: 30px;
  }
  .modal-container{
    text-align: center;
    .modal-inputs {
      margin-top: 20px;
    }
    .modal-actions{
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }
  .contact-container {
    text-align: center;
    margin-top: 30px;
  }
}
</style>
